import network from "@/api/network";

export default class Usercard {
  static async getUserCard(user_id, project_id) {
    return await network.get(`user/${user_id}/project/${project_id}/profile`, null, {});
  }

  static async moveDataFromCurator(data) {
    return await network.post("user/relations/change/curator", data, {});
  }

  static async addPoints(data) {
    return await network.post("points/user/add", data, {});
  }

  static async subPoints(data) {
    return await network.post("points/user/subtract", data, {});
  }

  static async getRepsliCodes(id) {
    return await network.get(`user/repsli/list/${id}`, null, {});
  }

  static async addRepsliCode(data) {
    return await network.post("user/repsli", data, {});
  }

  static async editRepsliCode(data) {
    return await network.put("user/repsli", data, {});
  }

  static async deleteRepsliCode(data) {
    return await network.delete("user/repsli", data, {});
  }
}
